import { Link } from "gatsby"
import resolveLink from "../../utils/resolveLink"
import React, { useEffect, useState } from "react"
import {
  connectHits,
  Index
} from "react-instantsearch-dom"
import Heading from "src/components/Heading"
import { SearchIcon } from '@heroicons/react/solid'

const PageHit = ({ hit }) => {
  return (
    <Link class="group relative" to={resolveLink(`/${hit.full_slug}`)}>
        <div class="relative w-full  bg-gray-200 overflow-hidden group-hover:opacity-75 group-hover:-translate-y-2 duration-300 aspect-[750/500] rounded-lg">
          { hit.featured_image && <img className="w-full h-full object-center lg:w-full lg:h-full" src={hit.featured_image.filename} alt={hit.featured_image.alt}/> }
        </div>
        <div class="mt-4 flex justify-between flex-col">
            <span className="block uppercase text-lightblue-300 font-bold text-[11px] mb-1 tracking-widest">{hit.component.replace(/-/g, ' ').replace(/_/g, ' ')}</span>
            <h3 className="text-darkblue text-[18px] leading-tight group-hover:text-brightgreen font-medium font-display duration-300">
                {hit.name}
            </h3>
        </div>
    </Link>    
  )
}

const Hits = ({ hits }) => {
  const [noResults, setNoResults] = useState()
  // Check if there are any search results
  useEffect(() =>{
    if( hits.length < 1 ) {
      setNoResults(true)
    } else {
      setNoResults(false)
    }
  }, [hits])

  return (
    <>
    <div className="max-w-7xl mx-auto mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
      {hits.map(hit => (
        <PageHit hit={hit} />
      ))}
    </div>
    {noResults &&
      <div className="max-w-2xl mx-auto flex flex-col justify-center items-center text-center gap-4 py-24">
        <svg className="h-8 w-8 stroke-darkblue" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" id='search-icon'>
          <path d="M7.25 12.75C10.5637 12.75 13.25 10.0637 13.25 6.75C13.25 3.43629 10.5637 0.75 7.25 0.75C3.93629 0.75 1.25 3.43629 1.25 6.75C1.25 10.0637 3.93629 12.75 7.25 12.75Z" stroke="current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.7508 14.25L11.4883 10.9875" stroke="current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <Heading size="h4" className="text-darkblue">We didn’t find any match for your search.</Heading>
      </div>
    }
    </>
  )
}

const CustomHits = connectHits(Hits);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <CustomHits />
  </Index>
)

const SearchResult = ({ indices, show }) => (
  <div>
    <Heading size="h6" className="text-darkblue font-display font-bold">Insights &amp; resources</Heading>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult
