import React from "react"
import { connectPagination } from 'react-instantsearch-dom';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <ul className="flex flex-row justify-center py-16">
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1;

      return (
        <li key={index}>
          <a
            href={createURL(page)}
            onClick={event => {
              event.preventDefault();
              refine(page);
            }}
            className={`px-4 py-3 text-base border-brightgreen hover:text-brightgreen duration-300 ${currentRefinement === page ? 'text-brightgreen border-t-2 font-bold' : 'text-blue'}`}
          >
            {page}
          </a>
        </li>
      );
    })}
  </ul>
);

export default connectPagination(Pagination);